import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

function GetLink(props) {
  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        allMenus(sort: "Sorting:asc") {
          id
          Label
          Primary_URL
          Secondary_URL
          Show_In_Top_Nav
          Sorting
          Main_Parent {
            id
            Label
            Primary_URL
            Secondary_URL
          }
          Sub_Parent {
            id
            Label
            Primary_URL
            Secondary_URL
          }
        }
      }
    }
  `)

  let PageURL = data.glstrapi.allMenus
    .filter(item => item.Primary_URL === props?.link?.Primary_URL)
    .pop()
  return (
    <>
      {PageURL.Primary_URL &&
        PageURL.Main_Parent == null &&
        PageURL.Sub_Parent == null &&
        PageURL.Secondary_URL == null && (
          <Link className={props.className} to={`/${PageURL.Primary_URL}/`}>
            {props.label ? props.label : PageURL.Label}
          </Link>
        )}
      {PageURL.Primary_URL &&
        PageURL.Main_Parent != null &&
        PageURL.Sub_Parent == null &&
        PageURL.Secondary_URL == null && (
          <Link
            className={props.className}
            to={`/${PageURL.Main_Parent.Primary_URL}/${PageURL.Primary_URL}/`}
          >
            {props.label ? props.label : PageURL.Label}
          </Link>
        )}
      {PageURL.Primary_URL &&
        PageURL.Main_Parent != null &&
        PageURL.Sub_Parent != null &&
        PageURL.Secondary_URL == null && (
          <Link
            className={props.className}
            to={`/${PageURL.Main_Parent.Primary_URL}/${PageURL.Sub_Parent.Primary_URL}/${PageURL.Primary_URL}/`}
          >
            {props.label ? props.label : PageURL.Label}
          </Link>
        )}
      {PageURL.Secondary_URL != null && (
        <Link className={props.className} to={`${PageURL.Secondary_URL}`}>
          {props.label ? props.label : PageURL.Label}
        </Link>
      )}
    </>
  )
}

export default GetLink
