import React from 'react';
import { Container, Col, Row, Accordion } from "react-bootstrap";
import { Link } from 'gatsby';
import './assets/styles/_index.scss';

const PopularSearch = (props) => {
    return (
        <section className="popular-search-wrapper">
            <Container>
                <Row>
                    <Col lg={12}>
                        <Accordion className="popular-search-accordion">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Popular Searches</Accordion.Header>
                                <Accordion.Body>
                                    <Row className="accordion-row">
                                    {props.popularsearch !== "Rentals" &&
                                        <Col lg={4} md={6}>
                                            <ul>
                                                <li>
                                                    <Link to="/property/for-sale/in-crouch-end/">Property for sale in Crouch End</Link>
                                                </li>
                                                <li>
                                                    <Link to="/property/for-sale/in-islington/">Property for sale in Islington</Link>
                                                </li>
                                                <li>
                                                    <Link to="/property/for-sale/in-camden/">Property for sale in Camden</Link>
                                                </li>
                                                <li>
                                                    <Link to="/property/for-sale/in-finsbury-park/">Property for sale in Finsbury Park</Link>
                                                </li>
                                            </ul>
                                        </Col>
                                        }
                                        {props.popularsearch !== "Sales" &&
                                        <Col lg={4} md={6}>
                                            <ul>
                                                <li>
                                                    <Link to="/property/to-rent/in-crouch-end/">Property to rent in Crouch End</Link>
                                                </li>
                                                <li>
                                                    <Link to="/property/to-rent/in-islington/">Property to rent in Islington</Link>
                                                </li>
                                                <li>
                                                    <Link to="/property/to-rent/in-camden/">Property to rent in Camden</Link>
                                                </li>
                                                <li>
                                                    <Link to="/property/to-rent/in-finsbury-park/">Property to rent in Finsbury Park</Link>
                                                </li>
                                            </ul>
                                        </Col>
                                        }
                                        {props.popularsearch === "Sales" &&
                                        <Col lg={4} md={6}>
                                            <ul>
                                                <li>
                                                    <Link to="/property/for-sale/in-london/1-and-more-bedrooms/">1 bedroom property for sale in London</Link>
                                                </li>
                                                <li>
                                                    <Link to="/property/for-sale/in-london/2-and-more-bedrooms/">2 bedroom property for sale in London</Link>
                                                </li>
                                                <li>
                                                    <Link to="/property/for-sale/in-london/3-and-more-bedrooms/">3 bedroom property for sale in London</Link>
                                                </li>
                                                <li>
                                                    <Link to="/our-culture/latest-property-news/">Latest property news</Link>
                                                </li>
                                            </ul>
                                        </Col>
                                        }
                                        {props.popularsearch === "Rentals" &&
                                        <Col lg={4} md={6}>
                                            <ul>
                                                <li>
                                                    <Link to="/property/to-rent/in-london/1-and-more-bedrooms/">1 bedroom property to rent in London</Link>
                                                </li>
                                                <li>
                                                    <Link to="/property/to-rent/in-london/2-and-more-bedrooms/">2 bedroom property to rent in London</Link>
                                                </li>
                                                <li>
                                                    <Link to="/property/to-rent/in-london/3-and-more-bedrooms/">3 bedroom property to rent in London</Link>
                                                </li>
                                                <li>
                                                    <Link to="/our-culture/latest-property-news/">Latest property news</Link>
                                                </li>
                                            </ul>
                                        </Col>
                                        }
                                        <Col lg={4} md={6}>
                                            <ul>
                                                <li>
                                                    <Link to="/property-valuation/">Property valuation in Crouch End and Islington</Link>
                                                </li>
                                                <li>
                                                    <Link to="/contact/crouch-end-office/">Estate agents in Crouch End </Link>
                                                </li>
                                                <li>
                                                    <Link to="/contact/islington-office/">Estate agents in Islington</Link>
                                                </li>
                                                <li>
                                                    <Link to="/our-culture/areas-we-cover/">Crouch End & Islington area guides</Link>
                                                </li>
                                            </ul>
                                        </Col>

                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default PopularSearch