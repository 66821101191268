import { graphql, useStaticQuery } from "gatsby"
import React from 'react'
import { Link } from "gatsby"
import { OfficeBaseURL } from "../common/site/utils"

const OfficeData = () => {
    const data = useStaticQuery(graphql`
    query GetMenuOffice {
        glstrapi {
            offices {
                id
                Name
                URL
                Office_Address
                Phone
            }
        }
    }
`)
    const offices = data.glstrapi.offices

    return (
        <div className="office-menu-wrapper">
            {offices && offices.map((office) => {
                return <>
                    {(office.URL != "property-management") &&
                        <div className="office-enclose">
                            <div className="office-mn-name">{office.Name}</div>
                            <div className="office-mn-address">{office.Office_Address}</div>
                            <div className="office-mn-phone">{office.Phone}</div>
                            <div className="office-mn-actions">
                                <Link to="/contact-us/">Email Office</Link><span>/</span>
                                <Link to={OfficeBaseURL + office.URL + "/ "}>View Details</Link>
                            </div>
                        </div>
                    }
                </>
            }
            )}
            <div className="mn-social-icons">
                <a
                    href="https://twitter.com/DavidAstburys?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
                    target="_blank"
                    className="icon-twitter-dark"
                ></a>
                <a
                    href="https://www.facebook.com/DavidAstburysEstateAgency/"
                    target="_blank"
                    className="icon-fb-dark"
                ></a>
                <a
                    href="https://www.instagram.com/davidastburys/"
                    target="_blank"
                    className="icon-instagram-dark"
                ></a>
                <a
                    href="https://www.linkedin.com/company/david-astburys/"
                    target="_blank"
                    className="icon-linkedin-dark"
                ></a>
            </div>
        </div>
    )
}

export default OfficeData