import { Link, graphql, useStaticQuery } from "gatsby";
import React from "react";
import { isMobileOnly } from "react-device-detect";
import ReviewSection from "../ReviewSection/ReviewSection";
import "./AppointmentBanner.scss";
import GetLink from "../functions/GetLink";
import { useLocation } from "@reach/router"

const AppointmentBanner = ({ searchResults }) => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      glstrapi {
        globalConfig {
          promo_banner {
            title
            show_reviews
            id
            cta {
              id
              CTA_Secondary_Link
              CTA_Label
              CTA_Link {
                Label
                Primary_URL
                Secondary_URL
                id
              }
            }
          }
          promo_banner_search_results {
            title
            show_reviews
            id
            cta {
              id
              CTA_Secondary_Link
              CTA_Label
              CTA_Link {
                Label
                Primary_URL
                Secondary_URL
                id
              }
            }
          }
        }
      }
    }
  `);

  const { promo_banner, promo_banner_search_results } = data.glstrapi.globalConfig;

  const bannerData = searchResults ? promo_banner_search_results : promo_banner;
  const {pathname} = useLocation();
  return (
    pathname !== "/our-culture/" &&
    <div className="review-badge-group appointment-banner-wrapper">
      <div className="top_section">
        <ReviewSection />
      </div>
      <h2>{bannerData.title}</h2>
      {bannerData.cta.CTA_Secondary_Link ? (
        <Link to={bannerData.cta.CTA_Secondary_Link} className="btn btn-white-outline btn">
          {bannerData.cta.CTA_Label}
        </Link>
      ) : (
        <GetLink
          className={`btn btn-white-outline btn`}
          label={bannerData.cta.CTA_Label}
          link={bannerData.cta.CTA_Link}
        />
      )}
      {isMobileOnly && <ReviewSection />}
    </div>
  );
};

export default AppointmentBanner;
