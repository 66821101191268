import { useQuery } from "@apollo/client"
import { Queries } from "./common_queries"

export const RecentNews = () => {
  return useQuery(Queries.RecentNews)
}
export const RecentEvents = () => {
  return useQuery(Queries.RecentEvents)
}
export const NewsCategories = () => {
  return useQuery(Queries.NewsCategories)
}
export const AllNews = () => {
  return useQuery(Queries.AllNews)
}
export const OtherNews = id => {
  return useQuery(Queries.OtherNews, {
    variables: { id: id },
  })
}
export const GetOffices = () => {
  return useQuery(Queries.GetOffices)
}
export const AllTeams = () => {
  return useQuery(Queries.AllTeams)
}
export const AllPartners = () => {
  return useQuery(Queries.AllPartners)
}

export const NoResultProperties = (status, department) => {
  return useQuery(Queries.NoResultProperties, {
    variables: { status: status, department: department },
  })
}
export const GetPropertyBooking = (id) => {
  return useQuery(Queries.GetPropertyBooking,{
      variables: {id: id}
  });
}

export const AreaGuideDetail = (postcode) => {
  return useQuery(Queries.AreaGuideDetail,{
      variables: {postcode: postcode}
  });
}

export const AreaGuidePopular = (url) => {
  return useQuery(Queries.AreaGuidePopular,{
      variables: {url}
  });
}

export const GetPropertyOffice = id => {
  return useQuery(Queries.GetPropertyOffice, {
    variables: { id: id },
  })
}
