import gql from "graphql-tag"
/* ========== Recent News ======== */
const RecentNews = gql`
  query GetNews {
    newsInsights(limit: 6) {
      id
      URL
      Title
      Above_Content
      Description
      News_Date
      Tile_Image {
        url
        alternativeText
      }
      imagetransforms
    }
  }
`

/* ========== Recent Events ======== */
const RecentEvents = gql`
  query GetNews {
    newsInsights(limit: 6, sort: "News_Date:desc", where: { select_categories: { Name: "Events" } }) {
      id
      URL
      Title
      Above_Content
      Description
      News_Date
      select_categories {
        id
        Name
        URL
      }
      Tile_Image {
        url
        alternativeText
      }
      imagetransforms
    }
  }
`

/* ========== Get News Category ======== */
const NewsCategories = gql`
  query GetNewsCategory {
    newsCategories {
      id
      Name
      URL
    }
  }
`
/* ========== All News ======== */

const AllNews = gql`
  query GetNews {
    newsInsights(sort: "News_Date:desc") {
      id
      URL
      Title
      News_Date
      Tile_Image {
        url
        alternativeText
      }
      select_categories {
        id
        Name
        URL
      }
      imagetransforms
      Video_URL
    }
  }
`

/* ========== Other News ======== */
const OtherNews = gql`
  query OtherNews($id: ID!) {
    newsInsights(sort: "News_Date:desc", limit: 3, where: { id_ne: $id }) {
      id
      Title
      URL
      Tile_Image {
        url
        alternativeText
      }
      select_categories {
        id
        Name
        URL
      }
      imagetransforms
      Video_URL
    }
  }
`

/* ========== Get Offices ======== */
const GetOffices = gql`
  query GetOffices {
    offices {
      id
      Name
      URL
    }
  }
`
/* ========== All AllTeams ======== */

const AllTeams = gql`
  query GetAllTeams {
    teams {
      id
      Name
      URL
      Designation
      Image {
        url
        alternativeText
      }
      Select_Office {
        id
        Name
        URL
      }
      imagetransforms
    }
  }
`

/* ========== All Parnerts ======== */

const AllPartners = gql`
  query GetAllPartners {
    teams( 
      where: { Partner: true }
    ) {
      id
      Name
      URL
      Designation
      Image {
        url
        alternativeText
      }
      Select_Office {
        id
        Name
        URL
      }
      imagetransforms
    }
  }
`

/* ========== No Results Properties ======== */
const NoResultProperties = gql`
  query NoResultProperties($status: String!, $department: String!) {
    properties(
      limit: 6
      where: { search_type: $status, department: $department, publish: true }
    ) {
      id
      display_address
      search_type
      status
      floor_area
      floorarea_type
      slug
      title
      department
      price
      images
      crm_negotiator_id
      bedroom
      reception
      bathroom
      description
    }
  }
`

/* ========== Property Detail For Booking ======== */

const GetPropertyBooking = gql`
query GetPropertyOffice($id: String!) {
  properties (where:{id:$id}) {
    id
    slug
    display_address
    search_type
    department
    title
    images
    crm_id
    bedroom
    bathroom
    office_crm_id
    price
    price_qualifier
  }
}`

/* ========== Property Area Guide ======== */
const AreaGuideDetail = gql`
query AreaGuideDetail($postcode: String!) {
    areaguides (where:{Postcode_Mapping_contains:$postcode}, limit:1) {
      id
      Name
      URL
      Description
      Postcode_Mapping
      Tile_Image {
        url
      }
      Banner_Image {
        url
        alternativeText
      }
      Modules {
        __typename
        ... on ComponentComponentsAreaguideTileBlock {
          Content
          Title
        }
      }
      imagetransforms
      Video_Link
  }
}`

/* ========== Property Area Guide ======== */
const AreaGuidePopular = gql`
query AreaGuideDetail($url: String!) {
    areaguides (where:{URL:$url}, limit:1) {
      id
      Name
      URL
      Description
      Postcode_Mapping
      Banner_Image {
        url
        alternativeText
      }
      imagetransforms
      Video_Link
  }
}`


/* ========== Get Property Office ======== */

const GetPropertyOffice = gql`
query GetPropertyOffice($id: String!) {
  offices (where:{office_crm_id:$id}) {
    id
    URL
    Name
    Tile_Image {
      url
      alternativeText
    }
    Phone
    office_crm_id
  }
}`


export const Queries = {
  RecentNews,
  RecentEvents,
  NewsCategories,
  AllNews,
  OtherNews,
  GetOffices,
  AllTeams,
  AllPartners,
  NoResultProperties,
  GetPropertyBooking,
  AreaGuideDetail,
  GetPropertyOffice,
  AreaGuidePopular
}
