import * as qs from "query-string"

const AltSiteName = " - David Astburys"
const NewsBaseURL = "/our-culture/latest-property-news/"
const TeamBaseURL = "/our-culture/meet-the-team/"
const AreaguideBaseURL = "/our-culture/areas-we-cover/"
const OfficeBaseURL = "/contact/"
const ValuationURL = "/property-valuation/"

export { AltSiteName, NewsBaseURL, TeamBaseURL, AreaguideBaseURL, OfficeBaseURL, ValuationURL }

// scroll
export const scrollToElement = (element, offset = 1) => {
    const scrollOffset = offset
    const bodyRect = document.body.getBoundingClientRect().top
    const elementRect = element.getBoundingClientRect().top
    const elementPosition = elementRect - bodyRect
    const offsetPosition = elementPosition - offset
  
    typeof window !== "undefined" &&
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      })
}

  /**Send Mail*/
export const SendMail = (postData) => {
    window.fetch(`${process.env.GATSBY_CLOUD_URL}/api/form`, {
      method: `POST`,
      mode: "no-cors",
      headers: {
        'Access-Control-Allow-Origin': '*',
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: qs.stringify(postData),
    })
}