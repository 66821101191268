import React from 'react';
import { Container, Col, Row, Accordion } from "react-bootstrap";
import { Link } from 'gatsby';
import './assets/styles/_index.scss';

const PopularSearch = (props) => {
    var searchaction = props.searchaction
    var searchslug = searchaction.replace(/\s+/g, '-').toLowerCase();
    var searchareaslug = props.area ? props.area : "london";
    var searchareaslug2 = searchareaslug.replace(/\s+/g, '-').toLowerCase();
    searchareaslug2 = searchareaslug2.substr(0, searchareaslug2.length-1);
    var searchareaname =  props.area ? props.area : "London";

    let areaguideUrl = props?.areaguides?.URL
    let areaguideName = props?.areaguides?.Name
    return (
        <section className="popular-search-wrapper">
            <Container>
                <Row>
                    <Col lg={12}>
                        <Accordion className="popular-search-accordion">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Popular Searches</Accordion.Header>
                                <Accordion.Body>
                                    <Row className="accordion-row">
                                        <Col lg={4} md={6}>
                                            <ul>
                                                <li>
                                                    <Link to={`/property/${searchslug}/in-crouch-end/`}>Property {searchaction} in Crouch End</Link>
                                                </li>
                                                <li>
                                                    <Link to={`/property/${searchslug}/in-islington/`}>Property {searchaction} in Islington</Link>
                                                </li>
                                                <li>
                                                    <Link to={`/property/${searchslug}/in-camden/`}>Property {searchaction} in Camden</Link>
                                                </li>
                                                <li>
                                                    <Link to={`/property/${searchslug}/in-finsbury-park/`}>Property {searchaction} in Finsbury Park</Link>
                                                </li>
                                            </ul>
                                        </Col>
                                      
                                        <Col lg={4} md={6}>
                                            <ul>
                                                <li>
                                                    <Link to={`/property/${searchslug}/in-${searchareaslug2}/1-and-more-bedrooms/`}>1 bedroom property {searchaction} in {searchareaname}</Link>
                                                </li>
                                                <li>
                                                    <Link to={`/property/${searchslug}/in-${searchareaslug2}/2-and-more-bedrooms/`}>2 bedroom property {searchaction} in {searchareaname}</Link>
                                                </li>
                                                <li>
                                                    <Link to={`/property/${searchslug}/in-${searchareaslug2}/3-and-more-bedrooms/`}>3 bedroom property {searchaction} in {searchareaname}</Link>
                                                </li>
                                                <li>
                                                    <Link to={`/property/${searchslug}/in-${searchareaslug2}/4-and-more-bedrooms/`}>4 bedroom property {searchaction} in {searchareaname}</Link>
                                                </li>
                                            </ul>
                                        </Col>
                                      
                                         <Col lg={4} md={6}>
                                            <ul>
                                                <li>
                                                    <Link to={`/our-culture/areas-we-cover/${areaguideUrl}/`}>{areaguideName} Area Guide</Link>
                                                </li>
                                                <li>
                                                    <Link to={`/contact/`}>Estate agents in {searchareaname} </Link>
                                                </li>                                               
                                                <li>
                                                    <Link to={`/property-valuation/`}>Property valuation in {searchareaname}</Link>
                                                </li>
                                                <li>
                                                    <Link to={`/our-culture/`}>About David Astburys estate agents</Link>
                                                </li>
                                                
                                            </ul>
                                        </Col>

                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default PopularSearch