import { useEffect, useState } from "react"

export function on(obj, ...args) {
  if (obj && obj.addEventListener) {
    obj.addEventListener(...args)
  }
}

export function off(obj, ...args) {
  if (obj && obj.removeEventListener) {
    obj.removeEventListener(...args)
  }
}

export const isBrowser = typeof window !== "undefined"

const patchHistoryMethod = (method) => {
  const { history } = window
  const original = history[method]

  // eslint-disable-next-line func-names
  history[method] = function (state) {
    // eslint-disable-next-line prefer-rest-params
    const result = original.apply(this, arguments)
    const event = new Event(method.toLowerCase())

    event.state = state

    window.dispatchEvent(event)

    return result
  }
}

if (isBrowser) {
  patchHistoryMethod("pushState")
  patchHistoryMethod("replaceState")
}

const useLocationServer = () => ({
  trigger: "load",
  length: 1,
})

const buildState = (trigger) => {
  const { state, length } = window.history

  const {
    hash,
    host,
    hostname,
    href,
    origin,
    pathname,
    port,
    protocol,
    search,
  } = window.location

  return {
    trigger,
    state,
    length,
    hash,
    host,
    hostname,
    href,
    origin,
    pathname,
    port,
    protocol,
    search,
  }
}

const useLocationBrowser = () => {
  const [state, setState] = useState(buildState("load"))

  useEffect(() => {
    const onPopstate = () => setState(buildState("popstate"))
    const onPushstate = () => setState(buildState("pushstate"))
    const onReplacestate = () => setState(buildState("replacestate"))

    on(window, "popstate", onPopstate)
    on(window, "pushstate", onPushstate)
    on(window, "replacestate", onReplacestate)

    return () => {
      off(window, "popstate", onPopstate)
      off(window, "pushstate", onPushstate)
      off(window, "replacestate", onReplacestate)
    }
  }, [])

  return state
}

const hasEventConstructor = typeof Event === "function"

export default isBrowser && hasEventConstructor
  ? useLocationBrowser
  : useLocationServer