import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router"
import "./ReviewSection.scss";
import WhiteGoogleReview from "../../images/google-review-white.svg";

const ReviewSection = ({title}) => {
  const {pathname} = useLocation();
  return (
    
    <div className="reviews_badge">
      <Helmet>
        <script src="https://apps.elfsight.com/p/platform.js" type="text/javascript" async="true"></script>
      </Helmet>
      {pathname === "/our-culture/" && 
      
          <div className="review-img-section">
            <img src={WhiteGoogleReview} alt="g-review" className="white-g-review"/>
          </div>
      }
      
      {pathname === "/our-culture/" ?
      
      <div className="elfsight-app-63e70e72-f4a6-4c33-93a1-04e2d0873939"></div>:
        <div className="elfsight-app-2d2211b4-3022-4dd1-afe8-1a052a630fb9"></div>
      }
      
    </div>
  );
};

export default ReviewSection;
