import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { LiveChatWidget } from "@livechat/widget-react";
import { useWidgetChatData, useWidgetIsReady } from "@livechat/widget-react";

const YomdelChatScript = () => {
  // State to manage chat settings
  const [chatSettings, setChatSettings] = useState({
    licenseId: null,
    groupId: null,
    chatVisibility: 'minimized',
    showChat: true
  });
  
  // Custom hooks to get chat data and widget readiness
  const chatData = useWidgetChatData();
  const isWidgetReady = useWidgetIsReady();

  // Effect to handle chat visibility based on chat data and state
  useEffect(() => {
    // Check if the widget is ready before processing chat data
    if (!isWidgetReady) return;

    // Set up timeout to check chat status after a delay
    const timeoutId = setTimeout(() => {
      // Check if chat is in progress
      if (chatData != null && chatData.chatId) {
        // Chat is in progress, do nothing
      } else {
        // Chat is not in progress
        if (chatSettings.chatVisibility === "hidden") {
          // If chat visibility is set to hidden, hide the chat
          setChatSettings(prevSettings => ({
            ...prevSettings,
            showChat: false
          }));
        }
      }
    }, 3000); // Check after 3 seconds

    // Clean up timeout when component unmounts or dependencies change
    return () => clearTimeout(timeoutId);
  }, [isWidgetReady, chatData, chatSettings.chatVisibility]);

  // Effect to fetch chat script and update chat settings
  useEffect(() => {
    const fetchChatScript = async () => {
      try {
        // Fetch chat script from the Yomdel API
        const currentURL = window.location.href;
        const response = await axios.get(`https://clients.yomdel.com/tools/chat_script.js?url=${currentURL}`);
        const respscript = response.data;

        // Parse license and group IDs from chat script
        const licenseMatch = respscript.match(/window\.__lc\.license = (\d+);/);
        const groupMatch = respscript.match(/window\.__lc\.group = (\d+);/);
        const chatMatch = respscript.match(/var livechat_chat_started = (\w+);/);

        // Initialize variables to store chat settings
        let { licenseId, groupId, chatVisibility } = chatSettings;
        
        // Parse license and group IDs from chat script
        if (licenseMatch && groupMatch) {
          licenseId = licenseMatch[1];
          groupId = groupMatch[1];
        } else {
          const regex = /\d+/g;
          const matches = respscript.match(regex);
          if (matches && matches.length >= 2) {
            licenseId = matches[0];
            groupId = matches[1];
          }
        }

        // Update chat visibility based on chat script
        if (chatMatch) {
          const chatVisibilityMatch = chatMatch[1];
          if (!chatVisibilityMatch || chatVisibilityMatch === 'false') {
            chatVisibility = 'hidden';
          }
        }

        // Update chat settings with fetched values
        setChatSettings(prevSettings => ({
          ...prevSettings,
          licenseId,
          groupId,
          chatVisibility
        }));
      } catch (error) {
        console.error("Error fetching script:", error);
        // Handle error gracefully, e.g., set state to indicate error
      }
    };

    // Fetch chat script when component mounts
    fetchChatScript();

    // Clean up when the component unmounts
    return () => {
      // Perform any cleanup here if needed
    };
  }, []); // Empty dependency array to run this effect only once

  return (
    <>
      {/* Render LiveChatWidget only if license ID is present and showChat is true */}
      {chatSettings.licenseId && chatSettings.showChat && (
        <LiveChatWidget
          license={chatSettings.licenseId}
          group={chatSettings.groupId}
          visibility={chatSettings.chatVisibility}
        />
      )}
    </>
  );
};

export default YomdelChatScript;
