import React from 'react';
import { Container, Col, Row, Accordion } from "react-bootstrap";
import { Link } from 'gatsby';
import './assets/styles/_index.scss';

const PopularSearch = (props) => {
    var area = props.area
    var areaname = area.replace(' Office','')
    var areasulug = areaname.replace(/\s+/g, '-').toLowerCase();

    let areaURL, areaName, offName, offUrl;

    if(area == "Crouch End Office") {
        areaName = "Islington"
        areaURL = "/contact/islington-office/"
        offName = "Property management in Crouch End"
        offUrl = "/contact/property-management/"

    } else if (area === "Islington Office") {
        areaName = "Crouch End"
        areaURL = "/contact/crouch-end-office/"
        offName = "Property management in Crouch End"
        offUrl = "/contact/property-management/"
    }
    return (
        <section className="popular-search-wrapper">
            <Container>
                <Row>
                    <Col lg={12}>
                        <Accordion className="popular-search-accordion">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Popular Searches</Accordion.Header>
                                <Accordion.Body>
                                    <Row className="accordion-row">
                                    
                                        <Col lg={4} md={6}>
                                            <ul>
                                                <li>
                                                    <Link to={`/property/for-sale/in-${areasulug}/`}>Property for sale in {areaname}</Link>
                                                </li>
                                                <li>
                                                    <Link to={`/property/for-sale/in-${areasulug}/1-and-more-bedrooms/`}>1 bedroom property for sale in {areaname}</Link>
                                                </li>
                                                <li>
                                                    <Link to={`/property/for-sale/in-${areasulug}/2-and-more-bedrooms/`}>2 bedroom property for sale in {areaname}</Link>
                                                </li>
                                                <li>
                                                    <Link to={`/property/for-sale/in-${areasulug}/3-and-more-bedrooms/`}>3 bedroom property for sale in {areaname}</Link>
                                                </li>
                                                
                                            </ul>
                                        </Col>
                                        <Col lg={4} md={6}>
                                            <ul>
                                                <li>
                                                    <Link to={`/property/to-rent/in-${areasulug}/`}>Property to rent in {areaname}</Link>
                                                </li>
                                                <li>
                                                    <Link to={`/property/to-rent/in-${areasulug}/1-and-more-bedrooms/`}>1 bedroom property to rent in {areaname}</Link>
                                                </li>
                                                <li>
                                                    <Link to={`/property/to-rent/in-${areasulug}/2-and-more-bedrooms/`}>2 bedroom property to rent in {areaname}</Link>
                                                </li>
                                                <li>
                                                    <Link to={`/property/to-rent/in-${areasulug}/3-and-more-bedrooms/`}>3 bedroom property to rent in {areaname}</Link>
                                                </li>
                                                
                                            </ul>
                                        </Col>
                                        <Col lg={4} md={6}>
                                            <ul>
                                                <li>
                                                    <Link to={areaURL ? areaURL : "/contact/islington-office/"}>Estate agents in {areaName ? areaName : "Islington"}</Link>
                                                </li>                                          
                                                <li>
                                                    <Link to={offUrl ? offUrl : "/contact/crouch-end-office/"}>{offName ? offName : "Estate agents in Crouch End"} </Link>
                                                </li>
                                                <li>
                                                    <Link to="/property-valuation/">Property valuation in London</Link>
                                                </li>                                            
                                                <li>
                                                    <Link to="/our-culture/">About David Astburys estate agents</Link>
                                                </li>
                                            </ul>
                                        </Col>

                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default PopularSearch