import React, {useState} from 'react';
import { Container, Col, Row, Accordion } from "react-bootstrap";
import { Link } from 'gatsby';
import './assets/styles/_index.scss';
import { AreaGuidePopular } from '../../queries/common_use_query';
import _ from 'lodash';

const PopularSearch = (props) => {

      //Get values from refine search
  const [ptype, setPtype] = useState(false)
  const [searchbed, setsearchbed] = useState(false)
  var searchparams = props.searchParams 
  var searchaction = ''
  var URL = ''
  var ptypeURL = ''
  var bedURL = ''
  var bedtypeText = ''
  var area = 'london'
  var typeText = 'Property'
  var searcharea = ''
  var searchareaText = ''
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  if (searchparams.pType == 'sales') {
    searchaction = 'for Sale'
    URL = '/property/for-sale/'
  }
  if (searchparams.pType == 'lettings') {
    searchaction = 'to Rent'
    URL = '/property/to-rent/'
  }
  if (searchparams.type) {
    ptypeURL = searchparams.type
    typeText = capitalizeFirstLetter(searchparams.type.replace(/-/g, ' '))
  }
  if (searchparams.bedrooms) {
    bedtypeText = searchparams.bedrooms + ' bedroom '
    bedURL = '/' + searchparams.bedrooms + '-and-more-bedrooms'
  }
  //console.log("$$$$", props.Searcharea && props.Searcharea.areas !='undefined' && props.Searcharea.areas)
  if (searchparams.areas && searchparams.areas  != 'undefined') {
    searcharea = searchparams.areas.toLowerCase().replace(/ /g,"-");
    searcharea = searcharea.replace(/%20/g, "-")
    searchareaText = searchparams.areas.replace(/%20/g, " ")
    searchareaText = capitalizeFirstLetter(searchareaText)
  }

  const goToTop = () => {
    if (typeof window !== 'undefined') {
        window.scrollTo(0,0)
    }
  }

  const {loading:loading, error:error, data:data} = AreaGuidePopular(searchparams.areas_alias);
  const areaguide = !_.isEmpty(data?.areaguides) && data?.areaguides[0]

  const popularAreaguide = {
    url : areaguide ? `/our-culture/areas-we-cover/${areaguide.URL}/` : "/our-culture/areas-we-cover/",
    name: areaguide ? areaguide.Name : "London"
  }



    return (
        <section className="popular-search-wrapper">
            <Container>
                <Row>
                    <Col lg={12}>
                        <Accordion className="popular-search-accordion">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Popular Searches</Accordion.Header>
                                <Accordion.Body>
                                    <Row className="accordion-row">
                                        <Col lg={4} md={6}>
                                            <ul>
                                                <li>
                                                    <Link to={`${URL}in-crouch-end/`} onClick={() => goToTop()}>Property {searchaction} in Crouch End</Link>
                                                </li>
                                                <li>
                                                    <Link to={`${URL}in-islington/`} onClick={() => goToTop()}>Property {searchaction} in Islington</Link>
                                                </li>
                                                <li>
                                                    <Link to={`${URL}in-camden/`} onClick={() => goToTop()}>Property {searchaction} in Camden</Link>
                                                </li>
                                                <li>
                                                    <Link to={`${URL}in-finsbury-park/`} onClick={() => goToTop()}>Property {searchaction} in Finsbury Park</Link>
                                                </li>
                                            </ul>
                                        </Col>
                                        {searchparams.bedrooms ?

                                        <Col lg={4} md={6}>
                                            <ul>
                                                <li>
                                                    <Link to={`${URL}in-crouch-end/${searchparams.bedrooms}-and-more-bedrooms/`} onClick={() => goToTop()}>{bedtypeText} property {searchaction} in Crouch End</Link>
                                                </li>
                                                <li>
                                                    <Link to={`${URL}in-islington/${searchparams.bedrooms}-and-more-bedrooms/`} onClick={() => goToTop()}>{bedtypeText} property {searchaction} in Islington</Link>
                                                </li>
                                                <li>
                                                    <Link to={`${URL}in-camden/${searchparams.bedrooms}-and-more-bedrooms/`} onClick={() => goToTop()}>{bedtypeText} property {searchaction} in Camden</Link>
                                                </li>
                                                <li>
                                                    <Link to={`${URL}in-finsbury-park/${searchparams.bedrooms}-and-more-bedrooms/`} onClick={() => goToTop()}>{bedtypeText} property {searchaction} in Finsbury Park</Link>
                                                </li>
                                            </ul>
                                        </Col>
                                        :
                                        <Col lg={4} md={6}>
                                            <ul>
                                                <li>
                                                    <Link to={`${URL}in-${searcharea}/1-and-more-bedrooms/`} onClick={() => goToTop()}>1 bedroom property for sale in {searchareaText}</Link>
                                                </li>
                                                <li>
                                                    <Link to={`${URL}in-${searcharea}/2-and-more-bedrooms/`} onClick={() => goToTop()}>2 bedroom property for sale in {searchareaText}</Link>
                                                </li>
                                                <li>
                                                    <Link to={`${URL}in-${searcharea}/3-and-more-bedrooms/`} onClick={() => goToTop()}>3 bedroom property for sale in {searchareaText}</Link>
                                                </li>
                                                <li>
                                                    <Link to={`${URL}in-${searcharea}/4-and-more-bedrooms/`} onClick={() => goToTop()}>4 bedroom property for sale in {searchareaText}</Link>
                                                </li>
                                            </ul>
                                        </Col>
                                        }
                                        <Col lg={4} md={6}>
                                            <ul>
                                                <li>
                                                    <Link to={popularAreaguide.url}>{popularAreaguide.name} area guide</Link>
                                                </li>
                                                <li>
                                                    <Link to="/contact/">Estate agents in {searchareaText}</Link>
                                                </li>
                                                <li>
                                                    <Link to="/property-valuation/">Property valuation in {searchareaText}</Link>
                                                </li>
                                                <li>
                                                    <Link to="/our-culture/">About David Astburys estate agents</Link>
                                                </li>
                                            </ul>
                                        </Col>

                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default PopularSearch